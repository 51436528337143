import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import axios from "axios";

const firebaseConfig = {

  apiKey: "AIzaSyDhzWHdmCgftfLHBttlhS93GBsONumYQUs",

  authDomain: "chiguireland.firebaseapp.com",

  projectId: "chiguireland",

  storageBucket: "chiguireland.appspot.com",

  messagingSenderId: "1046657052155",

  appId: "1:1046657052155:web:5ba14d1316faa2111fef5e",

  measurementId: "G-9Y0SJXDWJK"

};

const baseUrl = "https://api2.chiguireland.com";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const jwtToken = await loginWithFirebaseToken(res.user.accessToken)
    
    localStorage.setItem('accessToken', jwtToken);

  } catch (err) {
  }
};

const loginWithFirebaseToken = async (firebaseToken) => {
  try {

    var data = {
      auth_type: "firebase",
      credentials: {
        accessToken: firebaseToken
      }
    };

    const loginResult = await axios.post(
      `${baseUrl}/login`, data
    );

    return loginResult.data.jwtToken

  } catch (err) {
    console.log(err)
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
  }
};
const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};