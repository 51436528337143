import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../styles/Dashboard.css";
import { auth, logout } from "../firebase/firebase";
import axios from "axios";
import Web3 from "web3";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();



  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const getAddress = async () => {
    if (window?.ethereum?.isMetaMask) {
      // Desktop browser
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const address = Web3.utils.toChecksumAddress(accounts[0]);

      return address
    }
  }

  const handleLogin = async () => {
    const address = await getAddress()

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('accessToken')
    }

    const baseUrl = "https://api2.chiguireland.com";
    const response = await axios.get(`${baseUrl}/message?address=${address}`, { headers: headers });
    const messageToSign = response?.data?.messageToSign;

    if (!messageToSign) {
      throw new Error("Invalid message to sign");
    }

    const web3 = new Web3(Web3.givenProvider);
    const signature = await web3.eth.personal.sign(messageToSign, address);

    const params = {
      submittedAddress: address,
      signature: signature
    };

    const registerResult = await axios.post(
      `${baseUrl}/registerAddress`, params, { headers: headers }
    );

    return registerResult


  };

  return (
    <div className="dashboard">
      <div className="dashboard__container">
        Bienvenido

        <div>{user?.displayName}</div>

        <button className="dashboard__btn" onClick={logout}>
          Logout
        </button>
        <button className="dashboard__btn" onClick={handleLogin}>
          Asociar Wallet a tu cuenta
        </button>

      </div>
    </div>
  );
}

export default Dashboard;